import * as React from "react"
import { Link } from "gatsby"
import Logo from "../assets/icons/Logo";
import Seo from "../components/Seo";
import Background from "../components/Background";
import Footer from "../components/Footer";
import clsx from "clsx";



const NotFoundPage = () => {
  return (
		<>
			<div
				className={
					"absolute w-screen flex flex-col justify-center items-center overflow-hidden top-0 left-0"
				}
			>
				{/* <div className={"relative w-full h-screen flex justify-evenly"}>
					<div className={"absolute w-full h-full flex justify-center items-center "}>
						<div className={clsx("w-full p-8", "md:w-2/3 lg:w-1/2")}>
							<Logo colour={"#173c58"} />
						</div>
					</div>
					<nav
						className={clsx(
							"w-full h-full flex items-end justify-center pb-8 z-10",
							"md:pb-16"
						)}
					>
						<div className={"flex flex-col gap-2 items-center"}>
							<Link
								to={`/`}
								className={
									"w-max text-xl text-terracotta underline decoration-1 underline-offset-2 hover:text-plaster"
								}
								state={{ direction: "fromHome" }}
							>
								Home
							</Link>
						</div>
					</nav>
				</div>
        <Footer /> */}
			</div>
			{/* {home.background && <Background background={home.background} />} */}
		</>
  );
}

export default NotFoundPage

export const Head = ({ location }) => {

	return (
		<Seo
			title={"Not Found"}
			pathname={location.pathname}
		/>
	);
};
